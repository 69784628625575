<template>
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div class="flex flex-ac">
          <div class="mr-30 flex flex-ac">
            <span class="fs-16 flex1 mr-20 w-100">系列名称：</span>
            <el-input class="w-250" v-model="retrieveForm.goodsName" clearable></el-input>
          </div>
          <div label="">
            <el-button type="primary" @click="submitSearch">查询</el-button>
          </div>
        </div>
        <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
        <template v-slot:img="scope">
          <img :src="scope.row.goodsImg" alt="" class="smallImg" v-if="scope.row.goodsImg">
        </template>
      </EleTable>
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
    </TableHeightFull>
    <!-- 精品详情 -->
    <quality-details ref="quality-details" @refresh="getTableData"></quality-details>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
import { mapState } from 'vuex'
export default {
  name: 'drawsGood',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "CountDown": (resolve) => require(["@/components/CountDown"], resolve),
    "quality-details": (resolve) => require(["@/views/modules/goods/qualityDetails"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'shelves': state => state.dictList ? state.dictList.shelves : [],  //是否上下架
    })
  },
  data () {
    return {
      retrieveForm: {
        searchValue: '',
        goodsName: '',
      },
      tabLoading: false,
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', fixed: 'left', index: this.indexMethod },
        { columnType: 'custom', label: '商品图片', prop: 'img', align: 'left', width: '80' },
        { label: '商品名称', prop: 'goodsName', align: 'left', 'show-overflow-tooltip': true },
        { label: '价格', prop: 'soldPrice', align: 'left', 'show-overflow-tooltip': true },
        { label: '创建时间', prop: 'createDate', align: 'left', 'show-overflow-tooltip': true },
        { label: '发货日期', prop: 'deliverDate', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'fun', label: '是否上架', prop: 'shelves', align: 'left', 'show-overflow-tooltip': true, formatData: (e) => {
            let ele = this.shelves.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '120',
          buttons: [
            { type: 'text', text: '编辑', fn: this.editList },
            { type: 'text', text: '删除', fn: this.deleteList }
          ],
        },
      ],
      tableData: [],
    }
  },
  mounted () {
    this.getTableData()
  },
  methods: {
    //查询
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableData()
    },
    //导出
    exportExcel () { },
    //获取表格数据
    getTableData () {
      this.tabLoading = true
      this.$http.get('/goodsMall/findGoodsMallList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          goodsName: this.retrieveForm.goodsName,
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      }).catch(err => {
        this.tabLoading = false
      })
    },
    //新增列表
    addList () {
      this.$refs['quality-details'].init()
    },
    //编辑
    editList (row) {
      this.$refs['quality-details'].init(row.id)
    },
    //删除
    deleteList (data) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/goodsMall/deleGoodsMall', { id: data.id }).then(res => {
          this.getTableData()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
      }).catch(_ => { })
    },
    //跳转详情
    headerRowClick (row) {
      console.log(row)
    },
  },
}
</script>

<style lang="less" scoped>
</style>